import { Injectable } from '@angular/core';
import { WORDS_DE } from './words.de';
import { Words } from './words.model';

@Injectable()
export class TranslationService {
    private words: Words; 

    constructor() {
        this.words = WORDS_DE;
    }
    
    public getTranslation(key: string) {
        let retValue: string;
        retValue = this.words[key];
        if (key !== undefined && key !== null && key.trim().length > 0 && retValue === undefined) {
            retValue = key;
        } 
        return retValue;
    }
}