<div id="indato-project">
    <!--TODO indato-fh5co-work with indato color-->
    <section #section id="fh5co-work">
        <div class="container">
            <div class="row">
                    <div class="col-md-12 indato-section-heading text-center">
                            <h2 class="to-animate fadeInUp animated">Projekte</h2>
                            <div class="row">
                                    <div class="col-md-8 col-md-offset-2 subtext to-animate fadeInUp animated">
                                            <h3>Hier stehen Infos zu den Highlights</h3>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="row row-bottom-padded-sm">
                    <div class="col-md-4 col-sm-6 col-xxs-12" *ngFor="let project of projects">
                            <a href="{{project.src}}" class="fh5co-project-item image-popup to-animate fadeInUp animated">
                                    <img src="{{project.src}}" alt="Image" class="img-responsive">
                                    <div class="fh5co-text">
                                    <h2>{{project.title}}</h2>
                                    <span>{{project.branding}}</span>
                                    </div>
                            </a>
                    </div>
            </div>
            <div class="row">
                    <div class="col-md-12 text-center to-animate fadeInUp animated">
                            <p>Hier vielleicht noch Anmerkungen zu Projekten</p>
                    </div>
            </div>
        </div>
    </section>
</div>
