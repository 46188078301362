<div id="indato-home">
    <section #section id="indato-fh5co-home">
        <div class="gradient"></div>
        <div class="container">
            <div class="text-wrap">
                <div class="text-inner">
                    <div class="row">
                        <div class="col-md-8 col-md-offset-2">
                            <!--               <h1 class="to-animate fadeInUp animated">Herzlich Willkommen</h1> 
                            -->
                            <picture>
                                <source srcset="assets/images/logo_indato_klein.png" media="(prefers-color-scheme:dark)">
                                <img class="to-animate fadeInUp animated" src="assets/images/logo_indato_klein.png" title="indato GmbH"> 
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="indato-slant"></div>
    </section>
</div>


