<div id="indato-testimonials">
    <!--TODO indato-fh5co-testimonials with indato color-->
    <section  #section id="fh5co-testimonials" data-section="testimonials"> 
            <div class="container">
                    <div class="row">
                            <div class="col-md-12 indato-section-heading text-center">
                                    <h2 class="to-animate">Ich bin eine Seite weissem Hintergrund</h2>
                                    <div class="row">
                                            <div class="col-md-8 col-md-offset-2 subtext to-animate">
                                                    <h3>Hier könnten Infos zu Angebot/Themen (aus alter Homepage) aufgelistet werden</h3>
                                            </div>
                                    </div>
                            </div>
                    </div>
                    <div class="row">
                            <div class="col-md-4">
                                    <div class="box-testimony">
                                            <blockquote class="to-animate-2">
                                                    <p>&ldquo;Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.&rdquo;</p>
                                            </blockquote>
                                            <div class="author to-animate">
                                                    <figure><img src="images/person1.jpg" alt="Person"></figure>
                                                    <p>
                                                    Jean Doe, CEO <a href="http://freehtml5.co/" target="_blank">FREEHTML5.co</a> <span class="subtext">Creative Director</span>
                                                    </p>
                                            </div>
                                    </div>
                            </div>
                            <div class="col-md-4">
                                    <div class="box-testimony">
                                            <blockquote class="to-animate-2">
                                                    <p>&ldquo;Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.&rdquo;</p>
                                            </blockquote>
                                            <div class="author to-animate">
                                                    <figure><img src="images/person2.jpg" alt="Person"></figure>
                                                    <p>
                                                    John Doe, Senior UI <a href="http://freehtml5.co/" target="_blank">FREEHTML5.co</a> <span class="subtext">Creative Director</span>
                                                    </p>
                                            </div>
                                    </div>
                            </div>

                        <!--

                            <div class="col-md-4">
                                    <div class="box-testimony">
                                            <blockquote class="to-animate-2">
                                                    <p>&ldquo;Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. &rdquo;</p>
                                            </blockquote>
                                            <div class="author to-animate">
                                                    <figure><img src="images/person3.jpg" alt="Person"></figure>
                                                    <p>
                                                    Chris Nash, Director <a href="http://freehtml5.co/" target="_blank">FREEHTML5.co</a> <span class="subtext">Creative Director</span>
                                                    </p>
                                            </div>
                                    </div>
                            </div>

                        -->
                    </div>
            </div>
    </section>
</div>


