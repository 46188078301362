<div id="indato-news">

    <section #section id="indato-fh5co-news">
        <div class="container">
            <div class="col-md-12 indato-section-heading text-center">
                <h2 class="to-animate fadeInUp animated">{{'nav_news' | translation}}</h2>
                <div class="row">
                    <div class="col-md-12 col-sm-12 fh5co-service to-animate fadeInUp animated">
                        <h3>{{'label_news_1' | translation}}</h3>
                        <p>{{'text_news_1' | translation}}</p>
                        <a href="https://opendata.ch/de/events/open-legal-lab-2024/" target="_blank"><img src="assets/images/logo_openlegallab.jpg" title="{{ 'label_news_1' | translation }}"></a>
                    </div>
                </div>
                <p></p>
                <div class="row">
                    <div class="col-md-12 col-sm-12 fh5co-service to-animate fadeInUp animated">
                        <h3><div [innerHTML]="'label_news_3' | translation"></div></h3>
                        <p [innerHTML]="'text_news_3' | translation"></p>
                        <a href="https://www.bj.admin.ch/bj/de/home/aktuell/mm.msg-id-100453.html" target="_blank"><img src="assets/images/logo_bj.png" title="{{ 'title_news_3' | translation }}"></a>
                    </div>
                </div>
                <p></p>
                <div class="row">
                    <div class="col-md-12 col-sm-12 fh5co-service to-animate fadeInUp animated">
                        <h3>{{'label_news_2' | translation}}</h3>
                        <p>{{'text_news_2' | translation}}</p>
                        <a href="https://www.curling-thun.ch/juniorinnen/juniorinnen1neu" target="_blank"><img src="assets/images/logo_ccthun.jpg" title="{{ 'title_news_2' | translation }}"></a>
                        <a href="https://www.curling.ch/newsroom/artikel/b-sm-gold-fuer-luzern-und-geneve/" target="_blank"><img src="assets/images/logo_swisscurling.png" title="{{ 'label_news_2' | translation }}"></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</div>    