<!-- <div id="indato-intro"> -->
    <section #section id="indato-intro">
        <div class="container">
            <div class="row inherit-height-parent">
                <div class="fh5co-block to-animate fadeInRight animated inherit-height" style="background-image: url(assets/images/entwicklung_v2.jpg);">
                    <div class="overlay-darker"></div>
                    <div class="overlay"></div>
                    <div class="fh5co-text">
                        <i class="fh5co-intro-icon icon-bulb"></i>
                        <h2>{{'development' | translation}}</h2>
                        <p class="indato-intro-p-height">{{'text_development' | translation}}</p>
                    </div>
                </div>
                <div class="fh5co-block to-animate fadeInRight animated inherit-height"  style="background-image: url(assets/images/software_v2.jpg);">
                    <div class="overlay-darker"></div>
                    <div class="overlay"></div>
                    <div class="fh5co-text">
                        <i class="fh5co-intro-icon icon-wrench"></i>
                        <h2>{{'maintenance' | translation}}</h2>
                        <p class="indato-intro-p-height">{{'text_maintenance' | translation}}</p>
                    </div>
                </div>
                <div class="fh5co-block to-animate fadeInRight animated inherit-height"  style="background-image: url(assets/images/beratung_v2.jpg);">
                    <div class="overlay-darker"></div>
                    <div class="overlay"></div>
                    <div class="fh5co-text">
                        <i class="fh5co-intro-icon icon-compass"></i>
                        <h2>{{'consultation' | translation}}</h2>
                        <p class="indato-intro-p-height">{{'text_consultation' | translation}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--
</div> -->
