<div id="indato-contact">
    
<section #section id="fh5co-contact">
    <div class="container">
        <div class="row">
            <div class="col-md-12 indato-section-heading text-center">
                <h2 class="to-animate fadeInUp animated">{{'nav_contact' | translation}}</h2>
            </div>
        </div>
        <div class="row row-bottom-padded-lg">
            <div class="col-md-2"></div>
            <div class="col-md-3 to-animate fadeInUp animated">
                <h3>{{'address' | translation}}</h3>
                <ul class="fh5co-contact-info">
                    <li class="fh5co-contact-address">
                            <i class="icon-home"></i>
                            Thunstrasse 10<br>3005 Bern
                     </li>
                    <li><i class="icon-phone"></i>(+41) 31 331 64 04</li>
                    <li><i class="icon-envelope"></i>info@indato.ch</li>
                    <li><i class="icon-map"></i><a href="http://maps.google.ch/maps?q=indato+GmbH,+Thunstrasse+10,+Bern+(indato+GmbH)&amp;hl=de&amp;sll=46.943055,7.451198&amp;sspn=0.005816,0.011373&amp;hq=indato+GmbH,&amp;hnear=Thunstrasse+10,+Kirchenfeld-Schosshalde,+3005+Bern&amp;t=m&amp;z=16" 
                       class="hyperlink_txt" title="Google Maps" rel="" onclick="return !window.open(this.href)">Google Maps
                    </a> 
                    </li>
                </ul>
            </div>
            
            <div class="col-md-6 to-animate fadeInUp animated">
                <h3>{{'approach' | translation}}</h3>
                <div class="image-popup to-animate">
                    <a href="http://maps.google.ch/maps?q=indato+GmbH,+Thunstrasse+10,+Bern+(indato+GmbH)&amp;hl=de&amp;sll=46.943055,7.451198&amp;sspn=0.005816,0.011373&amp;hq=indato+GmbH,&amp;hnear=Thunstrasse+10,+Kirchenfeld-Schosshalde,+3005+Bern&amp;t=m&amp;z=16" 
                       class="hyperlink_txt" title="Google Maps Link" rel="" onclick="return !window.open(this.href)">
                    <figure>
                     <img src="assets/images/plan_klein.jpg">
                    </figure>
                    </a> 
                </div>
            </div>
            
            <div class="col-md-1"></div>

        </div>
        
        <footer id="footer" role="contentinfo">
            
            <div class="row row-bottom-padded-sm">
                <a href="#indato-home" class="gotop js-gotop" pageScroll pageScrollAdjustHash="true" routerLink="/"><i class="icon-arrow-up2"></i></a>
            
            </div>
            <div class="text-center">
                <ul class="social social-circle">
                    <li><a href="https://in.linkedin.com/company/indato-gmbh" target="_blank"><i class="icon-linkedin"></i></a></li>
                </ul>
            </div>
        </footer>
    </div>

</section>

</div>
