import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
    
 public projects = [{
    title: "Swisscom",
    src: "assets/images/project/swisscom.png",
    branding: 'DevOps'
  }, {
    title: "SBB",
    src: "assets/images/project/sbb.png",
    branding: 'Testing'
  },{
    title: "Biel/Bienne",
    src: "assets/images/project/biel.png",
    branding: 'No idea what they do'
  }]
  
  constructor() { }

  ngOnInit() {
  }

}
