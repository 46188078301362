import { NgModule }      from '@angular/core';
import { CommonModule }      from '@angular/common';

import { TranslationPipe } from './translation.pipe';
import { TranslationService } from './translation.service';

@NgModule({
    imports: [CommonModule],       // module dependencies
    declarations: [TranslationPipe],   // components and directives
    providers: [TranslationService],                    // services
    exports: [TranslationPipe]
})
export class TranslationModule { }

