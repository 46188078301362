import {Pipe, PipeTransform} from '@angular/core';

import {TranslationService} from './translation.service';

@Pipe({name: 'translation', pure: false})
export class TranslationPipe implements PipeTransform {

    constructor(private translationService: TranslationService) {
    }

    transform(value: string, ...args: any[]): string {

        var retVal = this.translationService.getTranslation(value);
        for (let i = 0; i < args.length; i++) {
            retVal = retVal.replace("{" + i + "}", args[i])
        }
        return retVal;
    }
}